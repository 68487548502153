import React from "react";
import {
  Table,
  InputGroup,
  Form,
  Image,
  FloatingLabel,
  Alert,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import sha256 from "crypto-js/sha256";
import "bootstrap/dist/css/bootstrap.min.css";

var d = document;

export class LoadDataInTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  componentDidMount() {
    this.tick();
  }

  tick = () => {
    const option = { mode: "no-cors" };
    fetch(this.props.url, option)
      .then((response) => response.json())
      .then((json) => {
        /*const keyIndex = require('react-key-index')
        json = keyIndex(json, 1);*/

        this.setState({ data: json });
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <InputGroup>
            <input type="text" className="form-control" />
            <Button onClick={this.tick}>Cargar</Button>
          </InputGroup>
          <Table id="datos" striped>
            <thead>
              <tr>
                {(this.props.label_cols || []).map((element, index) => (
                  <td key={index}>{element}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((element, index) => (
                <tr key={element.id}>
                  {(this.props.name_cols || []).map((cols, index) => (
                    <td key={index}>{element[cols]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export class LoginBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                success: undefined,
                message: "",
                showAlert: false,
                data:  "",
                _html: ""
    };
  }

  componentDidMount() {
    if ('credentials' in navigator) {
      navigator.credentials.get({ password: true }).then(cred => {
        if (cred){
            let user = document.querySelector("#user");
            let password = document.querySelector("#password");
            user.value = cred.id
            password.value = cred.password;
            this.loginOnClickButton()
            console.log(cred)
        }else{
            console.log('No se selecciono ninguna opción')
        }
      });
    }
  }

  componentWillUnmount() {



  }

  loginOnClickButton = () => {

    let password = d.getElementById("password");

    let hashPassword = sha256(password.value).toString();

    password.value = hashPassword;

    const option = {
      method: "POST",
      body: new FormData(d.getElementById(this.props.formId)),
    };

    fetch(this.props.action, option)
      .then(response => response.json())
      .then(responseData => {
        console.log(responseData);

        this.setState({
          success: responseData.success,
          message: responseData.message,
          showAlert: true,
          data: responseData.data,
        });

        d.getElementById("password").value = "";

        if (responseData.success){
          this.setState({data : responseData.payload.template})
          window.location = responseData.redirect; 
        }

      })
      .catch(responseData => {
        console.log(responseData);
        d.getElementById("password").value = "";
        this.setState({
          success: false,
          message: "Ups!! Algo no funciono...Lo estamos resolviendo",
          showAlert: true,
          valuePassword: "",
          data: "",
        });
      });
  };

  render() {
    //const getParams = new URLSearchParams(document.location.search);
    //let redirect = getParams.get("redirect");
    //if (redirect == null) redirect = "";
  
    return (
      <>
      <div dangerouslySetInnerHTML={{__html: this.state.data}}/>
      <div className="row">
        <div className="container d-flex h-100 justify-content-center align-self-center">
          <div className="col-md-5 h-50">
            <div className="d-flex h-100 justify-content-center align-self-center pt-5 pb-2">
              
              <Image src={this.props.logo}></Image>
            </div>
            {this.state.showAlert ? (
              <Alert
                key={this.state.success ? "success" : "danger"}
                variant={this.state.success ? "success" : "danger"}
                onClose={() => this.setState({ showAlert: false })}
                dismissible
              >
                {this.state.message}
              </Alert>
            ) : (
              ""
            )}
            <Form id={this.props.formId}>
              <FloatingLabel controlId="user" label="Usuario" className="mb-3">
                <Form.Control type="text" name="user" />
              </FloatingLabel>
              <FloatingLabel controlId="password" label="password" className="mb-3">
                <Form.Control type="password" name="password" />
              </FloatingLabel>
              <Form.Control type="hidden" name="redirect" />
              <Button onClick={this.loginOnClickButton} className="w-100">
                Iniciar Sesión
              </Button>
            </Form>
          </div>
        </div>
      </div>
      </>
    );
  }
}
