import React from "react";
import ReactDOM from "react-dom/client";
import { LoginBox } from "./elements";



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div className="container">
    <LoginBox formId="f_login" action="./auth.php"  logo="./img/logo.png"/>
  </div>
);
